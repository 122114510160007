.add-form-input {
  border: 0.5px solid #cdcdcd;
  font-size: 12px;
  height: 35px !important;
}
.add-form-input::placeholder {
  font-weight: 500;
}
.form-controls:focus {
  box-shadow: none;
  border: 1px solid #000;
}
.form-controls::placeholder {
  font-weight: 500;
}
.form-field-btn {
  width: 28px;
  height: 28px;
  background-color: #5ac26a;
  border-radius: 8px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  display: grid;
  place-items: center;
}
.input-with-icon {
  position: relative;
}
.input-icon {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.errorMessage{
  color: red;
  font-size: 12px;
}
@media (max-width: 768px) {
  .table-responsives td:nth-child(3),
  .table-responsives th:nth-child(3) {
    display: none;
  }
}

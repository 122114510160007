.a {
    width: 100%;
    height: 100%;
    opacity: 0.76;
    background-color: #242424;
    z-index: 1;
    position: absolute;
}

.logo {
    margin: 8.5% 2% 0 0;
    width: 44%;
}
.sh_welnetLogo {
    margin: 4% 2% 0 0;
    width: 44%;
}

.loginForm {
    width: 68%;
    margin: 7% 0 0 0;
}

.welcome {
    font-size: 20px;
    letter-spacing: 0.5px;
    line-height: 21px;
    font-weight: normal;
}

.signIn {
    font-size: 33px;
    line-height: 36px;
    letter-spacing: 1.7px;
    font-weight: 500;
}
.social_text {
    font-size: 15px;
    line-height: 36px;
    font-weight: 500;
    color: #000;
    text-align: left;
}
.social_icon_bg a {
    text-decoration: none !important;
}

.search_not_found {
    position: absolute !important;
    left: 41% !important;
}
@media only screen and (max-width: 576px) {
    .NoAEDtxt {
        font-size: 18px !important;
    }
    .signIn {
        letter-spacing:  unset;
    }
}

.form {
    margin-top: 7%;
    text-decoration: none;
}

p {
    margin: 0;
}

input {
    width: 100%;
    /* height:42px; */
    border-radius: 6px;
    border: solid 1px #888;
    padding: 2.3% 7%;
    font-size: 14px;
}

input:focus {
    border: 2px solid #545454;
    height: 40
}

.submit {
    width: 100%;
    height: 42px;
    border-radius: 6px;
    border: solid 1px #888;
    padding: 2.3% 7%;
    font-size: 14px;
}

.submit:focus {
    border: 2px solid #545454;
    height: 40
}

.email {
    font-size: 16px;
}

.forgotPass {
    font-weight: 500;
    margin: 5% 0px;
    font-size: 16px;
    text-decoration: none;
}

.or {
    margin: 5% 0px;
    font-size: 13px;
}

.iconImg {
    width: 100%;
}

.signUp {
    text-decoration: none;
    font-size: 15px;
    font-weight: 500;
}

.privacyLink {
    position: fixed;
    right: 10px;
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
}

.privacy {
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
}

.appStoreIcon {
    width: 110px;
}

.privacy:hover,
.privacyLink:hover {
    text-decoration: underline;
}

.checkBox {
    height: 22px;
    width: 22px;
    margin: 0 8px 0 0;
    border-radius: 2px;
    border: solid 1px #c4c4c4;
}

.checkBoxLable {
    flex-direction: row;
    display: flex;
    margin: 5% 0 4% 0;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 500;
}

.PasswordReset {
    width: 16%;
    margin-top: 8%;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.resetText {
    font-size: 14px;
    line-height: 14px;
    width: 80%;
    margin: 13% 0 3% 0;
    text-align: center;
}
.resetPassword{
    width: unset !important;
}
.verify-email {
    font-size: 14px;
    line-height: 14px;
    margin: 10% 0 1% 0;
    text-align: center;
}

.as {
    margin: 30px 0px;
}

.banner {
    height: 100%;
    position: relative;
}

.forgotPasswordForm {
    width: 68%;
    margin: 7% 0 0;
}

.eye-icon {
    float: right !important;
    background-color: transparent;
    margin-right: 10px;
    margin-top: -34px;
    position: relative;
    z-index: 1;
    width: 18px;
    height: 25px;
}

input::-ms-reveal,
input::-ms-clear {
    display: none;
}

.validation-alert {
    display: flex;
    flex-direction: column;
    padding: 0 0 0 15px;
    position: absolute;
}

/* MOBILE RESOLUTION STYLES */


@media only screen and (min-width: 768px) {
    .banner {
        position: fixed;
        min-height: 650px;
    }

    .formView {
        z-index: 1;
        position: absolute;
        right: 0;
    }
}
.welnet-button {
    background-color: #1471b0;
}
.social_icon_bg {
    background-color: rgb(255 255 255);
    border-radius: 8px;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2); 
    border-color: transparent; 
    margin: 3% 0px;
}
.outer_spacing {
    margin: 5% 0px;
}
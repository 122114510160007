.AED-icon {
   height: 131px;
   width: 131px;
}

.ready-for-use {
   height: 90px;
   width: 90px;
}

.activeTab {
   background-color: #c60970 !important;
}

.inspection-item {
   min-height: 63px;
}

.inspection-avatar {
   height: 40px !important;
   width: 40px !important;
}

.inspection-icons {
   max-height: 40px;
   max-width: 40px;
   font-size: 16px;
}

.infoToolTip {
   max-width: 50% !important;
}

.tab-arrow {
   width: 10px;
   /* background: ; */
   position: relative;
   border-width: 10px 10px 0px 10px;
   border-color: transparent;
   border-style: solid;
   border-top-color: var(--primary-color);
}

.dropdown-btn,
.dropdown-btn::after {
   height: 30px;
   display: none;
   width: 144px;
   min-width: 144px;
   font-size: 16px;
}

.switch-checkbox {
   display: none;
}

.switch-label {
   display: flex;
   align-items: center;
   justify-content: space-between;
   width: 45px;
   height: 23px;
   background: #e6e6e6;
   border-radius: 100px;
   position: relative;
   transition: background-color .2s;
   border: none;
}

.switch-label .switch-button {
   content: '';
   position: absolute;
   top: 1px;
   left: 1px;
   width: 22px;
   height: 22px;
   border-radius: 25px;
   transition: 0.2s;
   box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.switch-checkbox:checked+.switch-label .switch-button {
   left: calc(100% - 2px);
   transform: translateX(-100%);
}

.switch-label:active .switch-button {
   width: 30px;
}

.switch {
   display: none;
}

.switchLabel {
   display: flex;
   align-items: center;
   justify-content: space-between;
   cursor: pointer;
   width: 35px;
   height: 18px;
   background: #e6e6e6;
   border-radius: 100px;
   position: relative;
   transition: background-color .5s;
   border: none;
}

.switchLabel .switchButton {
   content: '';
   position: absolute;
   top: 1px;
   left: 1px;
   width: 16px;
   height: 16px;
   border-radius: 25px;
   transition: 0.2s;
   box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.switch:checked+.switchLabel .switchButton {
   left: calc(100% - 2px);
   transform: translateX(-100%);
}

.switchLabel:active .switchButton {
   width: 25px;
}

 .calendar-icon {
   position: absolute;
   right: 25px;
   top: 50%;
   transform: translateY(-50%);
 }
 

.location-search {
   border: 0px solid #cdcdcd;
   font-size: 14px;
   height: 20px;
   color: #818188;
   box-shadow: none;
   padding: 0 0 0 10px;
}

.location-search:focus {
   box-shadow: none;
   color: #545454;
   border: 0px;
}

.edit-btn {
   background-color: transparent;
   border: none;
   padding: 0px;
}

.edit-btn:disabled {
   color: #818188 !important;
}

.mar-0 {
   margin: 0vw !important;
}
.userTypeBtn{
   min-width: 120px;
   max-width: 150px;
   height:27px;
}
.userTypeBtn:active{
   background-color: #c60970 !important;
   color: #fff !important;
}

.readiness-image-big {
   object-fit: contain;
   width: 80px !important;
   min-height: 40px;
}

.notified-field {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   margin: 5px 0 0 0;
}

.padding-none {
   padding: 0px !important;
}

.inActiveTab {
   border-bottom: 2px solid #babcbe;
   color: #818188;
}

.activeTabClass {
   border-bottom: 2px solid #babcbe;
   color: #c60970
}

.accessory-red {
   /* filter: invert(11%) sepia(44%) saturate(72) hue-rotate(-119deg) brightness(100%) contrast(130%) */
   filter: saturate(222) hue-rotate(235deg) brightness(67%) contrast(200%)
}

.accessory-yellow {
   filter: invert(2%) sepia(7%) saturate(6) hue-rotate(-49deg) brightness(92%) contrast(130%)
}

/* .accessory-green{
   filter: invert(2%) sepia(7%) saturate(6) hue-rotate(30deg) brightness(100%) contrast(130%)
} */

#detailTabs {
   display: flex;
   overflow: auto;
   flex-wrap: nowrap;
}

.nav-item {
   cursor: pointer;
}
.curriculum-nav-item:hover {
   background-color: #fff !important;
   width: 100% !important;
   background-color: var(--blue-light) !important;
}
.tabMenu {
   cursor: pointer;
   position: relative;
   /* height: 50px; */
   padding: 0px;
}
@media only screen and (max-width: 576px) {
   .tabMenu{
      font-size: 13px;
   }
}

.line {
   border-radius: 2px;
   position: relative;
   height: 5px;
   width: 100%;
}

.tabMenu {
   transition: all 0.4s cubic-bezier(.65, -0.3, .5, 1.25);
}

.tabMenu.tabActive .line {
   background-color: #c60970;
   transition: all 0.4s cubic-bezier(.65, -0.3, .5, 1.25);
}

.tabMenu.tabActiveCourse .line {
   background-color: #c60970;
   transition: all 0.3s cubic-bezier(.65, -0.3, .5, 1.25);
}

.tabMenu.tabCenter.tabActive .line {
   transform: translate(100%);
}
.tabMenu.tabCenter.tabActiveCourse .line {
   transform: translate(100%);
}

.tabMenu.right.tabActive .line {
   transform: translate(200%);
}
.tabMenu.right.tabActiveCourse .line {
   transform: translate(100%);
}

.tabActive {
   position: absolute;
   top: 36px;
   width: 33.33% !important;
}
.tabActiveCourse {
   position: absolute;
   top: 45px;
   width: 50%;
}
/* .equipmentContainer {
   height: 100vh
} */

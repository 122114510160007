.web-btn {
    color: var(--primary-color);
    padding: 5px;
    /* margin-left: 10px; */
    border-radius: 50%;
    transition: all 0.4s ease;
}

.web-btn:hover {
    background-color: #d6d6d6;
}

.web-btn>svg {
    color: currentColor;
}

.dropdown-menu.show {
    display: block;
    z-index: 10;
    position: absolute;
    top: 10px;
    width: 18rem;
}

.webLogo {
    /* width: 70px;
    object-fit: contain;
    border-radius: 3px solid var(--primary-color); */
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
}

.webLogo img {
    width: 35px !important;
}
.webLogo p {
    color: #1471b0;
    font-weight: 500 !important;
    font-size: 16px;
}

.curriculum-logo {
    width: 8rem !important;
    object-fit: cover;
}
.curriculum-selected-logo {
    width: 3rem;
}
.sh-connect {
    width: 10rem;
    filter: brightness(0%) invert(100%) sepia(100%) saturate(0%) hue-rotate(172deg) brightness(101%) contrast(102%);
}

.small-switch input {
    border: 2px solid #f7f7f7;
    cursor: pointer;
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='rgba(256, 256, 256, 1)'/></svg>") !important;
    background-color: var(--primary-color);
}
.small-switch input:active{
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
}
.small-switch:active{
    outline: none;
    box-shadow: none;
}
.small-switch input:checked{
    background-color: var(--primary-color);
    border: 2px solid #f7f7f7;
}
.addressbar_container{
    margin-top: 4.5pc;
    height: 40%;
    font-size: 16px
}
.curr_name {
    color: #1471b0;
    font-weight: 500;
    font-size: 17px;
    text-transform: capitalize;
    word-wrap: nowrap;
    text-wrap: nowrap;
}
@media only screen and (max-width: 576px) {
.addressbar_container_admin{
    margin-top: 5.5pc !important;
}
}
@media only screen and (max-width: 767px) {
.curr_name{
    display: none;
}
}
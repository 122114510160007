body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*:focus-visible {
  outline-color: #545454 !important;
}

:root {
  scrollbar-color: var(--primary-color) rgba(245, 206, 227, 0.1) !important;
  --primary-color: var(--primary-color);
  --bg-primary-color: var(--primary-color);
  --white-color: #fff;
  --blue-light: #5b9cc8;
  --blue-lighten: #5ea2d0;
  --blue-sharp: #5b9cc8;
  --blue-sharpen: #5695c0;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

img {
  object-fit: cover;
}

.Shadow {
  box-shadow: 0 3px 6px 0 rgba(80, 168, 255, 0.4);
}

.header {
  background: #225;
}

.tabIcon {
  /* height: 20px; */
  width: 20px;
  margin: 10px;
}

.tabs {
  font-size: 18px;
  font-weight: 500;
}

.side-menu-indicator {
  background-color: #fff;
  width: 5px;
  border-radius: 10px;
  height: 35px;
  display: flex;
  align-self: center;
}

.days-options {
  font-size: 10px;
  width: 30px;
  height: 30px;
  display: inline-block;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  margin-right: 8px;
  margin-bottom: 4px;
  color: rgb(34, 30, 30);
  user-select: none;
  filter: drop-shadow(1px 1px 2px #88888f);
}

.InActiveTab {
  font-size: 16px;
  font-weight: 500;
}

.profileIcon {
  height: 40px;
  min-width: 40px;
  width: 40px !important;
  border: 1px solid var(--primary-color);
  overflow: hidden;
  object-fit: cover;
}

.btn-check:focus+.btn-light,
.btn-light:focus {
  box-shadow: none !important;
}

.add-btn {
  font-size: 14px;
  min-width: 140px;
  height: 30px;
}

.collapseble {
  text-overflow: ellipsis;
  overflow: hidden;
}

.pac-container {
  z-index: 1200;
}

.pressable {
  box-shadow: 0 3px 6px 0 rgba(80, 168, 255, 0.4);
  border-radius: 6px !important;
  border: none !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  background-color: #c60970 !important;
  color: #fff !important;
}

.pressable:hover,
.pressable:focus-visible {
  border-radius: 7px !important;
  box-shadow: 0 4px 7px 0 rgba(80, 168, 255, 0.4);
  outline: 2px solid #545454 !important
}

.textarea {
  resize: none !important;
}

.dropdown-menu:focus-visible {
  outline-color: #545454;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  background-color: #818188 !important;
}

.dropDownToggle {
  width: 100%;
  background-color: transparent !important;
  color: #545454 !important;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
}

.dropDownToggle:focus {
  background-color: transparent !important;
}

.dropDownToggle:disabled {
  background-color: transparent !important;
  opacity: 1 !important;
  border: 0.5px solid #cdcdcd !important;
}

.dropdown-toggle::after {
  /* display: none !important; */
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.5em solid #818188 !important;
  border-right: 0.5em solid transparent !important;
  border-bottom: 0;
  border-left: 0.5em solid transparent !important;
}

.modal-btn {
  min-width: 100px;
  height: 27px;
}

.description {
  word-wrap: break-word;
  word-break: break-all;
}

.profileDropIcon::after {
  display: none !important;
}

.searchInput {
  background-color: #f5f5f5 !important;
  -webkit-transition: width 1s ease-in-out;
  transition: width 1s ease-in-out;
  border-radius: 0px 6px 6px 0px !important;
}

.footer {
  margin-left: -9px;
  display: flex;
}

#menu_checkbox,
#menu_checkbox:checked {
  display: none;
}

.hamBurger {
  display: block;
  width: 30px;
  height: 60px;
  padding-top: 15px;
  cursor: pointer;
  margin-right: 10px;
  margin-left: 6px;
}

.hamBurger div {
  position: relative;
  height: 3px;
  background-color: #c60970;
  margin-top: 5px;
  transition: 0.3s ease transform, 0.2s ease width, 0.2s ease background-color;
  border-radius: 2px;
  display: block;
  width: 100%;
}

.curr_hamBurger div {
  background-color: #1471b0;
}

.hamBurger div:first-child {
  transform-origin: 0;
}

.hamBurger div:last-child {
  margin-bottom: 0;
}

.hamBurger div:nth-child(2) {
  right: 0;
  transition: 0.2s ease transform, 0.1s ease width, 0.2s ease background-color;
  border-radius: 2px;
}

#menu_checkbox:checked+.hamBurger div:first-child {
  transform: rotateZ(45deg) translate(3px, -6px);
  width: 35px;
}

#menu_checkbox:checked+.hamBurger div:last-child {
  transform: rotateZ(-45deg) translate(5px, -3px);
  width: 35px;
}

#menu_checkbox:checked+.hamBurger div:nth-child(2) {
  width: 0px;
  transform: translate(17px);
  background-color: #fff;
}

#menu_checkbox~div {
  display: none !important;
}

#menu_checkbox:checked~div {
  display: flex !important;
}

.mobileMenu {
  /* transition: all 2s ease; */
  width: 25%;
  z-index: 1;
  min-height: 100vh;
  top: 0;
  left: 0;
  margin-top: 64px;
  animation-name: example;
  animation-duration: 1s;
  position: fixed;
  animation-fill-mode: forwards;
  overflow-x: hidden;
}

.mobileMenu::-webkit-scrollbar {
  display: none;
}

/* GLOBAL COLOR STYLES */
.Bg-fff {
  background-color: #fff !important
}

.C-818188 {
  color: #818188 !important
    /* Secondary */
}

.C-dark {
  color: #545454 !important
    /*Dark gray */
}

/* SCROLL BAR STYLE */
*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgba(245, 206, 227, 0.1);
  border-radius: 10px;
}

*::-webkit-scrollbar {
  width: 7px;
  height: 6px;
  background-color: rgba(245, 206, 227, 0.1);
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-image: -webkit-gradient(linear,
      left bottom,
      left top,
      color-stop(0.25, #e678b4),
      color-stop(0.66, #e655a4),
      color-stop(0.8, #e61a8a));
}

@media only screen and (max-width: 575px) {
  .searchInput {
    width: 0px;
    z-index: 3;
    display: flex;
    align-self: center;
    background-color: #f5f5f5 !important;
    -webkit-transition: width 1s ease-in-out;
    transition: width 1s ease-in-out;
  }

  .searchInput:focus {
    width: 150px;
    display: inline !important;
  }

  .margin-xs {
    margin: 115px 0 0 0 !important;
  }
}

@media only screen and (max-width: 390px) {
  .modal-btn {
    min-width: 70px;
  }
}

@media only screen and (max-height: 500px) {
  .footer {
    display: none !important;
  }
}

@media only screen and (max-width: 576px) {
  .footer {
    display: none !important;
  }
}

.react-datepicker-wrapper {
  width: 100%;
}

.curr-btn {
  --bs-btn-padding-x: initial !important;
}

.accordion-button:not(.collapsed) {
  background-color: #e6e6e6 !important;
  border: none !important;
}

.accordion {
  --bs-accordion-btn-focus-box-shadow: none !important;
}
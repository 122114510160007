.formInputContainer {
  display: flex;
  justify-content: center;
  max-width: 100%;
}

.forms-items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 800px;
}

.inputContainer {
  background-color: white;
  border-radius: 10px;
  margin-top: 10px;
}

.inputContainerRadio {
  background-color: white;
  border-radius: 10px;
  margin-top: 10px;
  flex-direction: column;
}

.inputContainerHeader {
  background-color: white;
  border-radius: 10px;
  margin-top: 10px;
  border-top: 9px solid #c60970;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 800px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.multiple-choice-grid {
  width: 100%;
}

.multiple-choice-grid table {
  border-collapse: collapse;
  width: 100%;
}

.multiple-choice-grid th,
.multiple-choice-grid td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: center;
}

.multiple-choice-grid .selected {
  background-color: #e0e0e0;
}

.checkboxContainer {
  width: fit-content;
}

.headerContainer {
  background-color: '#000';
}

.buttonContainer {
  margin-top: 40px;
  margin-bottom: 40px;
}

.radioButtonContainer {
  color: 'black';
}

.radioInputContainer {
  width: fit-content;
}

.radiolableClass {
  background-color: orange;
  width: 20px;
  margin-left: 40px;
}

.form-check-input[type=radio] {
  /* outline: 1.5px solid #586ed4; */
}

.form-check-input[type=checkbox] {
  outline: 1px solid #000;
}

.table>:not(caption)>*>* {
  border-bottom-width: 0px;

}

.lableInput {
  font-weight: 500;
  font-size: 15px;
  padding: 2px;
  margin: 0;
}

.information-page-container {
  position: relative;
  overflow: hidden;
}

.information-page-container img {
  max-width: 100%;
  height: auto;
}

.required-asterisk {
  color: #dc3545;
  margin-left: 0.2pc;
}

.headingWidth {
  min-width: 149.5px;
}

#formInputDate {
  position: relative;
}

input#formInputDate::-webkit-calendar-picker-indicator {
  position: absolute;
  width: 90%;
  background-color: transparent;
  height: 100%;
  background-position: right;
  background-size: 9%;
}

#formInputTime {
  position: relative;
}

input#formInputTime::-webkit-calendar-picker-indicator {
  position: absolute;
  width: 84%;
  background-color: transparent;
  height: 100%;
  background-position: right;
  background-size: 10%;
}

@media only screen and (max-width: 500px) {
  input#formInputTime::-webkit-calendar-picker-indicator {
    background-size: 18%;
    right: 2px;
  }

  input#formInputDate::-webkit-calendar-picker-indicator {
    background-size: 15%;
  }
}

@media only screen and (max-width: 768px) {
  .headingWidth {
    min-width: 120.5px;
  }
}

@media only screen and (max-width: 500px) {
  .headingWidth {
    min-width: 78.5px;
  }
}

.manageWidths {
  min-width: 123.4px;
  max-width: 350px;
}

@media only screen and (max-width: 1092px) {
  .manageWidths {
    min-width: 120.5px;
    width: 120.5px;
  }
}

@media only screen and (max-width: 768px) {
  .manageWidths {
    min-width: 90px;
  }
}

@media only screen and (max-width: 500px) {
  .manageWidths {
    min-width: 50px;
  }
}

@media only screen and (max-width: 768) {
  .forms-items {
    max-width: 100%;
  }
}

@media only screen and (max-width: 768) {
  .inputContainerHeader {
    max-width: 100%;
  }
}

.form-submit-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: relative;
  width: 100%;
  box-sizing: border-box;
}

.submitMessageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.information-page-content {
  max-width: 50%;
  max-height: 100%;
  word-wrap: break-word;
}

.information-page-content img {
  max-width: 100%;
  height: auto;
  display: block;
}
.searchedText{
    font-size: 13px !important;
}
.notification_modal_img{
    height: 35px;
}
.modal-open {
    overflow: hidden;
}
.curriculumSearchItem {
    width: 80% !important;
}
.dropDown {
    border: 0.5px solid #cdcdcd;
    font-size: 12px;
    height: 30px;
}

.dropDown:focus {
    box-shadow: 0 3px 6px 0 rgba(80, 168, 255, 0.16);
    border: 2px solid #545454;
}


.form-control::file-selector-button{
    width: 100px;
    padding: 8px 18px;
    border-inline-end-width:0px;
    border-radius: 5px;
}

.btn-check:focus+.btn-primary,
.btn-primary:focus .btn-check:focus+.btn,
.btn:focus,
.btn-primary:hover {
    box-shadow: none !important;
    background: #910753;
}

.searchBtn {
    font-size: 12px;
    font-weight: 500;
    border: 0;
    box-shadow: 0;
}

.credential-confirmed{
    filter: brightness(0) saturate(100%) invert(69%) sepia(43%) saturate(492%) hue-rotate(78deg) brightness(87%) contrast(95%);
}
.credential-expired{
    filter:  brightness(0) saturate(100%) invert(8%) sepia(81%) saturate(4085%) hue-rotate(344deg) brightness(152%) contrast(108%);
}
.credential-expiring-soon{
    filter:brightness(0) saturate(100%) invert(85%) sepia(65%) saturate(417%) hue-rotate(357deg) brightness(86%) contrast(87%);
}

.modalHeader {
    font-size: 16px;
}

.lable {
    font-size: 14px;
    padding: 0;
    margin: 0;
}

.field {
    font-size: 12px;
    height: 27px;
}

.b {
    background: #000000ff;
}

.hide-scroll-bar {
    overflow-y: scroll;
    -ms-overflow-style: none;
    /* for Internet Explorer, Edge */
    scrollbar-width: none;
    /* for Firefox */
}

.hide-scroll-bar::-webkit-scrollbar {
    display: none;
    /* for Chrome, Safari, and Opera */
}

.status_icon {
    min-height: 28px !important;
    min-width: 28px !important;
    object-fit: contain;
}

.location-address {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1 !important;
    display: -webkit-box;
    line-clamp: 1;
    -webkit-box-orient: vertical;
}

.datePicker {
    padding-left: 0.5rem !important;
    padding-right: 1rem !important;
    color: #818188;
}